@import "../../../sass/variables.scss";

.appliance {
  padding: 30px 60px;
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media screen and (max-width: 950px) {
    flex-direction: column;
    padding: 0 30px;
    gap: 0;
  }

  @media screen and (max-width: 600px) {
    padding: 0;
  }

  &__title {
    font-size: 36px;
    font-weight: 600;
    line-height: 32px;
    color: $black-color;

    @media screen and (max-width: 600px) {
      font-size: 32px;
      line-height: 28px;
    }
  }

  &__image_wrapper {
    max-width: 900px;
    position: relative;
    flex-shrink: 2;
  }

  &__image {
    width: 100%;
    transition: all 0.2s linear;
    object-fit: cover;
    height: 500px;

    @media screen and (max-width: 950px) {
      width: 100%;
    }

    &__inactive {
      opacity: 0;
    }

    &__first {
      z-index: 10;
    }

    &__secondary {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;

      &_active {
        opacity: 1;
        z-index: 10;
      }
    }
  }

  &__button {
    padding: 8px 16px;
    display: flex;
    background-color: unset;
    justify-content: space-between;
    border: 1px solid $orange-color;
    border-radius: 30px;
    color: $black-color;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 14px;
    cursor: pointer;

    &__paragraph {
      margin: 0;
    }

    &_active {
      border-width: 2px;
      font-weight: 600;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-basis: 370px;
  }

  &__price {
    margin-top: 80px;
    border-top: 2px solid $black-color;
    display: flex;
    justify-content: space-between;

    &__button {
      padding: 8px 16px;
      text-align: center;
      background-color: $orange-color;
      border: none;
      border-radius: 30px;
      color: $black-color;
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
      margin-bottom: 14px;
      cursor: pointer;
    }
  }

  &__input {
    display: none;

    &:checked + .appliance__div_button:after {
      transform: scale(1.1);
    }

    &:checked + .appliance__div_button {
      padding: 3px;
    }
  }

  &__div_button {
    width: 1.25em;
    height: 1.25em;
    border: 2px solid $orange-color;
    border-radius: 50%;
    margin-right: 16px;
    box-sizing: border-box;
    padding: 2px;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      background-color: $orange-color;
      background-repeat: no-repeat;
      border-radius: 50%;
      transform: scale(0);
    }
  }

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-top: 1px solid $black-color;

    &:last-of-type {
      margin-bottom: 24px;
    }
  }

  &__div_wrapper {
    display: flex;
    align-items: center;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: $orange-color;
}

.swiper-slide {
  width: 100% !important;
  &-active {
    width: 100% !important;
  }
}
