@import "../../sass/variables.scss";

.models {
  padding: 30px 60px;

  @media screen and (max-width: 950px) {
    padding: 30px 30px;
  }

  @media screen and (max-width: 600px) {
    padding: 30px 0;
  }

  &__wrapper {
    display: flex;
    gap: 20px;
    padding-bottom: 60px;

    @media screen and (max-width: 600px) {
      padding-bottom: 36px;
    }
  }

  &__text {
    font-weight: 600;
    font-size: 40px;
    line-height: 39px;
    color: $black-color;
    margin: 0;
    flex-basis: 540px;

    @media screen and (max-width: 600px) {
      font-size: 36px;
      line-height: 32px;
    }
  }

  &__paragraph {
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    color: $black-color;
    margin: 0;

    @media screen and (max-width: 600px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__images {
    display: grid;
    column-gap: 20px;
    row-gap: 40px;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 950px) {
      grid-template-columns: 1fr;
      gap: 40px;
    }
  }

  &__image {
    width: 100%;
  }

  &__image_wrapper {
    position: relative;
    background-color: $pattens-blue-color;
  }

  &__image_paragraph {
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    position: absolute;
    top: -12px;
    left: 0;
    right: 0;
    width: 185px;
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;
    color: $white-color;
    // border: 2px dashed $black-color;
    padding: 2px 10px;
    background: $orange-color;
    background: 16px;

    // font-size: 16px;
    // line-height: 26px;
    // position: absolute;
    // bottom: -24px;
    // text-decoration: none;
    // margin-left: auto;
    // margin-right: auto;
    // color: $black-color;

    @media screen and (max-width: 600px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__image_link {
    background-color: $grey-color;
    border: 2px solid $white-color;
    border-radius: 30px;
    font-size: 16px;
    line-height: 26px;
    color: $white-color;
    padding: 14px 42px;
    position: absolute;
    bottom: -24px;
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;
    width: 184px;
    left: 0;
    right: 0;

    &_active {
      background: $orange-color;
      color: $black-color;
    }

    @media screen and (max-width: 600px) {
      font-size: 16px;
      line-height: 22px;
      padding: 12px 36px;
      width: 172px;
    }
  }
}
