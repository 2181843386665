@import "../../../sass/variables.scss";

.wallTexture {
  padding: 30px 60px;
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media screen and (max-width: 950px) {
    flex-direction: column;
    padding: 0 30px;
  }

  @media screen and (max-width: 600px) {
    padding: 0;
    gap: 0;
  }

  &__title {
    font-size: 36px;
    font-weight: 600;
    line-height: 32px;
    color: $black-color;

    @media screen and (max-width: 600px) {
      font-size: 32px;
      line-height: 28px;
    }
  }

  &__image_wrapper {
    max-width: 900px;
    position: relative;
    flex-shrink: 2;
  }

  &__swiper_wrapper {
    max-width: 900px;
    flex-shrink: 2;
    position: relative;
  }

  &__image {
    width: 100%;
    transition: opacity 0.2s linear;

    &__inactive {
      opacity: 0;
    }

    &__first {
      z-index: 10;
    }

    &__secondary {
      position: absolute;
      left: 0;
      opacity: 0;
      top: 0;

      &_active {
        opacity: 1;
        z-index: 10;
      }
    }
  }

  &__button {
    padding: 8px 16px;
    display: flex;
    background-color: unset;
    justify-content: space-between;
    border: 1px solid $orange-color;
    border-radius: 30px;
    color: $black-color;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 14px;
    cursor: pointer;

    &__paragraph {
      margin: 0;
    }

    &_active {
      border-width: 2px;
      font-weight: 600;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-basis: 300px;
  }

  &__price {
    margin-top: 80px;
    border-top: 2px solid $black-color;
    display: flex;
    justify-content: space-between;

    &__button {
      padding: 8px 16px;
      text-align: center;
      background-color: $orange-color;
      border: none;
      border-radius: 30px;
      color: $black-color;
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
      margin-bottom: 14px;
      cursor: pointer;
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: $orange-color;
}

.swiper-slide {
  width: 100% !important;
  &-active {
    width: 100% !important;
  }
}
