@import "../../sass/variables.scss";

.constructor {
  &__menu {
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    padding: 16px 0;
    margin: 0 auto;

    @media screen and (max-width: 600px) {
      overflow: auto;
      white-space: nowrap;
    }

    &__link {
      font-size: 16px;
      line-height: 26px;
      font-weight: 300;
      margin: 0;
      cursor: pointer;

      @media screen and (max-width: 600px) {
        margin-right: 12px;
        &:last-child {
          margin: 0;
        }
      }

      &_active {
        font-weight: 700;
        border-bottom: 2px solid $orange-color;
      }
    }
  }
}
