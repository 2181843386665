@import "../../../sass/variables.scss";

@mixin default-div {
  display: flex;
  justify-content: space-between;
}

@mixin paragraph {
  margin: 0;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.summary {
  padding: 30px 60px;
  display: flex;
  flex-direction: row;
  gap: 28px;

  @media screen and (max-width: 950px) {
    gap: 0;
    flex-direction: column;
    padding: 0 30px;
  }

  @media screen and (max-width: 600px) {
    padding: 0;
  }

  &__form {
    display: flex;
    flex-direction: column;
    max-width: 100%;

    &_label {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 4px;
    }

    &_field {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 18px;
      padding: 10px 8px;
      border: none;
      background-color: rgba(237, 237, 237, 0.5);
      border-radius: 7px;

      &:focus {
        outline: none;
        background-color: rgb(237 237 237);
      }
    }
    &__button {
      width: 270px;

      @media screen and (max-width: 950px) {
        width: 100%;
      }
    }
  }

  &__title {
    margin: 0 0 30px 0;
    font-size: 36px;
    font-weight: 600;
    line-height: 32px;
    color: $black-color;

    @media screen and (max-width: 600px) {
      font-size: 32px;
      line-height: 28px;
    }
  }

  &__div_model {
    border-top: 2px solid $black-color;
    border-bottom: 1px solid $black-color;
    @include default-div();
  }

  &__div_def {
    @include default-div();
  }

  &__image_wrapper {
    max-width: 900px;
    position: relative;
    flex-shrink: 2;
  }

  &__image {
    width: 100%;
    transition: all 0.2s linear;

    &__inactive {
      opacity: 0;
    }

    &__secondary {
      position: absolute;
      left: 0;
      opacity: 0;

      &_active {
        opacity: 1;
      }
    }
  }

  &__button {
    padding: 8px 16px;
    display: flex;
    background-color: unset;
    justify-content: space-between;
    border: 1px solid $orange-color;
    border-radius: 30px;
    color: $black-color;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 14px;
    cursor: pointer;

    &__paragraph {
      margin: 0;
    }

    &_active {
      border-width: 2px;
      font-weight: 600;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-basis: 440px;

    @media screen and (max-width: 950px) {
      margin-top: 40px;
    }
  }

  &__price {
    margin-top: 80px;
    border-top: 2px solid $black-color;
    display: flex;
    justify-content: space-between;

    &__button {
      padding: 8px 16px;
      text-align: center;
      background-color: $orange-color;
      border: none;
      border-radius: 30px;
      color: $black-color;
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
      margin-bottom: 14px;
      cursor: pointer;
    }
  }

  &__paragraph {
    @include paragraph();
  }

  &__paragraph_with_padding {
    @include paragraph();
    padding: 10px 0 10px 0;
  }

  &__div_with_border {
    padding-bottom: 10px;
    border-bottom: 1px solid $black-color;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: $orange-color;
}

.swiper-slide {
  width: 100% !important;
  &-active {
    width: 100% !important;
  }
}
