@import "../../sass/variables.scss";

.app {
  background: $white-color;
  font-family: "Lexend Deca", sans-serif;
  max-width: 1400px;
  padding: 40px 20px 40px 20px;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    padding-top: 30px;
  }

  &__logo {
    width: 150px;
    height: 50px;

    @media screen and (max-width: 600px) {
      width: 136px;
      height: 45px;
    }
  }
}
